'use client';
import { Box, Flex, Link, Text } from '@chakra-ui/layout';
import { Show } from '@chakra-ui/media-query';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import slugify from 'react-slugify';

import GlobalSearch from '@/app/_components/search/GlobalSearch';
import { ReactComponent as KiaLogo } from '@/app/_icons/kia-logo-white.svg';
import { setDealershipCookieOnUser } from '@/services/gtm.service';
import { ShoppingToolsMenu } from '@/services/menu/shoppingtools.service';
import { BlogItem } from '@/types/blog/blog.types';
import { NewCar } from '@/types/new-cars/new-cars.types';

import { Hamburger } from './hamburger/Hamburger';
import FinancialServices from './megamenu/FinancialServices';
import Vehicles from './megamenu/Vehicles';
import styles from './Nav.module.scss';

const ShoppingTools = dynamic(
  () => import('./megamenu/ShoppingTools').then((module) => module),
  {
    ssr: false,
  },
);

function KiaHeader({
  config,
  newCars,
  latestBlogPosts,
  selectedDealershipName,
  shoppingToolsItems,
}: {
  config: {
    search_quick_links: {
      title: string;
      url: string;
    }[];
  };
  newCars: NewCar[];
  latestBlogPosts: BlogItem[];
  selectedDealershipName?: string;
  shoppingToolsItems: ShoppingToolsMenu | null;
}) {
  const displayDealershipName = Boolean(selectedDealershipName);

  useEffect(() => {
    setDealershipCookieOnUser();
  }, []);

  return (
    <nav className={styles.stickyNav}>
      <Box
        className={styles.mainNav}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Flex
          className={styles.flexWrapper}
          justifyContent={'space-around'}
          maxW={'1440px'}
          width={'100%'}
          marginX={'auto'}
          height="83px"
          align={'center'}
        >
          <Show above="lg">
            <Box className={styles.KiaOneNavigation}>
              <ul>
                <li className={`${styles.menuItem} ${styles.KiaOneMegamenu}`}>
                  <Vehicles
                    newCars={newCars}
                    selectedDealershipName={selectedDealershipName}
                  />
                </li>
                <li className={`${styles.menuItem} ${styles.KiaOneMegamenu}`}>
                  <ShoppingTools shoppingToolsItems={shoppingToolsItems} />
                </li>
                <li className={styles.menuItem}>
                  <Link
                    role="link"
                    className={styles.menuLink}
                    href="/promotions"
                  >
                    Promotions
                  </Link>
                </li>
              </ul>
              {displayDealershipName ? (
                <>
                  <Link
                    role="link"
                    className={styles.logo}
                    href={`/dealerships/${slugify(selectedDealershipName)}`}
                  >
                    <KiaLogo
                      className={styles.micrositeLogo}
                      width={'80px'}
                      height={'20px'}
                    />
                    <Text
                      fontSize="xs"
                      className={styles.micrositeName}
                      color={'white'}
                      marginTop={2}
                      textAlign={'center'}
                    >
                      {selectedDealershipName}
                    </Text>
                  </Link>
                </>
              ) : (
                <Link role="link" className={styles.logo} href="/">
                  <KiaLogo width={'80px'} height={'20px'} />
                </Link>
              )}
              <ul>
                <li className={styles.menuItem}>
                  <Link role="link" className={styles.menuLink} href="/blog">
                    Experience Kia
                  </Link>
                </li>
                <li className={`${styles.menuItem} ${styles.KiaOneMegamenu}`}>
                  <FinancialServices />
                </li>
                {!displayDealershipName && (
                  <li className={styles.menuItem}>
                    <Link
                      role="link"
                      className={styles.menuLink}
                      href="/find-a-dealer"
                      data-cy="find-a-dealer"
                    >
                      Find a Dealer
                    </Link>
                  </li>
                )}
              </ul>
            </Box>
          </Show>
          <Box className={styles.iconsWrapper}>
            {displayDealershipName ? (
              <>
                <Link
                  role="link"
                  className={styles.micorsiteLogoWrap}
                  href={`/dealerships/${slugify(selectedDealershipName)}`}
                >
                  <KiaLogo
                    className={styles.micrositeLogo}
                    width={'80px'}
                    height={'20px'}
                  />
                  <Text
                    fontSize="xs"
                    className={styles.micrositeName}
                    color={'white'}
                  >
                    {selectedDealershipName}
                  </Text>
                </Link>
              </>
            ) : (
              <Link role="link" className={styles.KiaLogo} href="/">
                <KiaLogo width={'80px'} height={'20px'} />
              </Link>
            )}
            <Box display={'flex'} alignItems={'center'}>
              <Box className="headerGlobalSearchBtnMob">
                <GlobalSearch
                  config={config}
                  newCars={newCars}
                  latestBlogPosts={latestBlogPosts}
                />
              </Box>
              <Hamburger
                selectedDealershipName={selectedDealershipName}
                newCars={newCars}
                shoppingToolsItems={shoppingToolsItems}
              />
            </Box>
          </Box>
        </Flex>
        <Box className="headerGlobalSearchBtnDesk">
          <GlobalSearch
            config={config}
            newCars={newCars}
            latestBlogPosts={latestBlogPosts}
          />
        </Box>
      </Box>
    </nav>
  );
}

export default KiaHeader;
