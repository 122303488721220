'use client';
import cn from 'classnames';
import RouterLink from 'next/link';
import React, { useEffect, useState } from 'react';

import { ReactComponent as Down } from '@/app/_icons/downChevron_24px.svg';
import { ReactComponent as Up } from '@/app/_icons/upChevron_24px.svg';
import { useOpenFloatingCTA } from '@/hooks/floating-cta.hook';
import { getDownloadLink } from '@/services/link.service';

import { LeadFormType } from '../modals/enquire-forms/enquiry-forms/enquiry-forms';

import styles from './OffersCategory.module.scss';

type Links = {
  id?: number;
  title?: string;
  isEnquireForm?: boolean;
  leadFormType?: LeadFormType;
  isOpenInNewTab?: boolean;
  main_title?: string;
  isDownload?: boolean | undefined;
  link?: string;
}[];

type Props = {
  links?: {
    title: string;
    links: Links;
    isShowInMobile: boolean;
    isOpened: boolean;
  }[];
  documents?: {
    paia_manual: string;
    popia_notice: string;
  };
  dealershipId?: string;
};

function OffersCategory({ links, documents, dealershipId }: Props) {
  const [linksList, setLinksList] = useState(links);

  const openLeadForm = useOpenFloatingCTA();

  useEffect(() => {
    setLinksList(links);
  }, [links]);

  const openSubMenu = (index: number, value: boolean) => {
    if (!Array.isArray(linksList)) return;
    const arr = [...linksList];

    arr.forEach((item, i) => {
      if (i !== index && item.isShowInMobile) {
        item.isShowInMobile = false;
      }
    });
    if (arr[index]) {
      arr[index].isShowInMobile = value;
    }
    setLinksList(arr);
  };

  const openMore = (index: number) => {
    if (!Array.isArray(linksList)) return;

    const arr = [...linksList];

    if (arr[index]) {
      arr[index].isOpened = !arr[index].isOpened;
    }

    setLinksList(arr);
  };

  const blankLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
  };

  const getDocumentLink = (documentType: 'paia_manual' | 'popia_notice') => {
    return documents ? getDownloadLink(documents[documentType]) : '';
  };

  return (
    <div className={styles.offersWrapper}>
      {linksList?.map((offer, index) => (
        <div key={offer.title} className={styles.offersItem}>
          <h2 className={styles.offersTitle}>
            {offer.title === 'New Cars' ? (
              <RouterLink href="/new-cars" data-cy="offer-title">
                New Cars
              </RouterLink>
            ) : offer.title === 'Services' ? (
              <RouterLink
                data-cy="offer-title"
                href=""
                onClick={blankLinkClick}
              >
                Services
              </RouterLink>
            ) : offer.title === 'New KIA Specials' ? (
              <RouterLink href="/specials/new-cars/kia" data-cy="offer-title">
                {offer.title}
              </RouterLink>
            ) : offer.title === 'Contact' ? (
              dealershipId ? (
                <span data-cy="offer-title">Contact</span>
              ) : (
                <RouterLink href="/find-a-dealer" data-cy="offer-title">
                  Contact
                </RouterLink>
              )
            ) : offer.title === 'Legal' ? (
              <RouterLink href="/legal/privacy-policy" data-cy="offer-title">
                Legal
              </RouterLink>
            ) : (
              ''
            )}
            <span className={styles.iconWrapper}>
              {offer.isShowInMobile ? (
                <Up onClick={() => openSubMenu(index, false)} />
              ) : (
                <Down onClick={() => openSubMenu(index, true)} />
              )}
            </span>
          </h2>
          <div
            key={'showSubMenu'}
            className={cn(styles.offersItemsWrapper, {
              [styles.showSubMenu as string]: offer.isShowInMobile,
            })}
            data-cy="new-car-list"
          >
            {offer?.links?.length === 0 && (
              <div
                className={styles.offersItemText}
                key={'No-vehicles-available'}
              >
                No vehicles available
              </div>
            )}
            {offer?.links?.length > 10 ? (
              <React.Fragment key={index}>
                {offer?.links?.map((obj: any, i: number) =>
                  !offer.isOpened && i > 9 ? null : (
                    <RouterLink
                      href={obj.link}
                      key={i}
                      className={styles.offersItemText}
                      data-cy="offer-item"
                    >
                      {obj.title}
                    </RouterLink>
                  ),
                )}
                <span
                  className={styles.offersItemMore}
                  onClick={() => openMore(index)}
                  data-cy="view-more"
                >
                  {offer.isOpened ? 'Hide more' : 'View more'}
                </span>
              </React.Fragment>
            ) : (
              offer?.links?.map((obj, i: number) =>
                obj.isEnquireForm ? (
                  <p
                    key={i}
                    className={cn(styles.offersItemText, styles.form)}
                    onClick={() => {
                      obj.leadFormType && openLeadForm(obj.leadFormType);
                    }}
                  >
                    {obj.title}
                  </p>
                ) : obj.link === '/find-a-dealer' && dealershipId ? (
                  <span key={i} className={styles.offersItemText}>
                    {obj.title}
                  </span>
                ) : (
                  <RouterLink
                    href={
                      obj.title === 'PAIA Manual'
                        ? getDocumentLink('paia_manual')
                        : obj.title === 'POPIA Notice'
                          ? getDocumentLink('popia_notice')
                          : obj.link
                    }
                    key={i}
                    className={styles.offersItemText}
                    target={
                      obj.isDownload || obj.isOpenInNewTab
                        ? '_blank'
                        : undefined
                    }
                    rel={obj.isDownload ? 'noreferrer' : undefined}
                    data-cy="offer-item"
                  >
                    {obj.title}
                  </RouterLink>
                ),
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default OffersCategory;
