'use client';
import { Button } from '@chakra-ui/button';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import cn from 'classnames';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { Suspense, useEffect, useTransition } from 'react';

import { useFloatingCTA } from '@/hooks/floating-cta.hook';
import type { PopiaNoticeField } from '@/types/config/config.types';
import { NewCar } from '@/types/new-cars/new-cars.types';

import {
  EnquiryForms,
  LeadFormEnum,
  LeadFormType,
} from '../modals/enquire-forms/enquiry-forms/enquiry-forms';

import Style from './BannerFloatingCTA.module.scss';

const LABELS = {
  '/after-sales/book-a-service': 'Book a service',
  '/after-sales/parts-and-accessories': 'Enquire about parts',
  '/corporate-fleet-solutions/.*': 'Enquire Now',
  '/find-a-dealer': 'Find a dealer',
  '/new-cars/.*': 'Enquire Now',
  general: 'Enquire Now',
} as { [key: string]: string };

const DEFAULT_TYPES = {
  '/after-sales/book-a-service': 'service',
  '/after-sales/parts-and-accessories': 'parts',
  '/corporate-fleet-solutions/.*': 'fleet',
  '/new-cars/.*': 'new-cars',
  '/find-a-dealer': 'general',
} as { [key: string]: LeadFormType };

const MOBILE_HIDDEN = ['/build-price/.*'];

const ALWAYS_HIDE_CTA = [
  '/used-cars',
  '/used-cars/search',
  '/used-cars/demo/search',
  '/used-cars/demo',
];

const USED_CAR_HIDE_PATTERN = /^\/used-cars\/(?!(.*\/\d+$)).+$/;

type Props = {
  btnText?: string;
  className?: string;
  extraClass?: string;
  moduleClass?: string;
  children?: React.ReactNode;
  newCars: NewCar[];
  dealerName?: string;
  documentPaiaPopia: PopiaNoticeField;
};

const BannerFloatingCTA = ({
  className = 'btnRed',
  extraClass = '',
  moduleClass = 'btnRed',
  children,
  newCars,
  dealerName,
  documentPaiaPopia,
  ...otherProps
}: Props) => {
  const { isCTAShown, isDrawerOpen, openLeadForm, closeLeadForm, leadType } =
    useFloatingCTA();

  const pathname = usePathname();
  const params = useSearchParams();
  const form = params.get('form');

  useEffect(() => {
    if (form && Object.values(LeadFormEnum).includes(form as LeadFormEnum)) {
      openLeadForm(form as LeadFormEnum);
      // Remove from params:
      const url = new URL(window.location.href);

      url.searchParams.delete('form');
      window.history.replaceState({}, '', url);
    }
  }, [form]);

  const matchedPath = Object.keys(LABELS).find((key) =>
    new RegExp(key).test(pathname),
  );
  const buttonText = matchedPath ? LABELS[matchedPath] : LABELS['general'];
  const mobileHidden = MOBILE_HIDDEN.some((path) =>
    new RegExp(path).test(pathname),
  );
  const shouldShowCTA = () => {
    if (ALWAYS_HIDE_CTA.includes(pathname)) {
      return false;
    }

    if (USED_CAR_HIDE_PATTERN.test(pathname)) {
      return false;
    }

    return true;
  };

  const [isPending, startTransition] = useTransition();

  return (
    <>
      {isCTAShown && shouldShowCTA() && (
        <div
          className={cn(
            Style.floatingCtaBtn,
            { [Style.hideCta]: !isCTAShown },
            moduleClass,
            { [Style.mobileHidden]: mobileHidden },
          )}
          key="cta"
          {...otherProps}
        >
          <Button
            className={cn(
              Style.btn,
              {
                [Style[className]]: className,
                [Style[extraClass]]: extraClass,
              },
              moduleClass,
            )}
            onClick={() => {
              startTransition(() => {
                openLeadForm(
                  matchedPath
                    ? DEFAULT_TYPES[matchedPath] || 'general'
                    : 'general',
                );
              });
            }}
            {...otherProps}
          >
            <span>
              {isPending ? 'Opening...' : children ? children : buttonText}
            </span>
          </Button>
        </div>
      )}

      <Suspense fallback={null}>
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          onClose={() => {
            closeLeadForm();
          }}
        >
          <DrawerOverlay />
          <DrawerContent maxWidth={{ base: '100%', md: '460px' }}>
            <DrawerCloseButton />
            <DrawerBody padding={10}>
              <EnquiryForms
                type={isDrawerOpen ? leadType : null}
                onClose={() => closeLeadForm()}
                newCars={newCars}
                dealerName={dealerName}
                documentPaiaPopia={documentPaiaPopia}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Suspense>
    </>
  );
};

export default BannerFloatingCTA;

